.user-portfolio-container {
  text-align: left;
  margin: 12px;
}

.user-portfolio-container .title {
  font-size: 12px;
  opacity: 50%;
}

.user-portfolio-container .value {
  font-size: 18px;
}

.user-portfolio-container .action {
  align-items: center;
}