.action-cards .swiper-slide {
    display: block;
}

.action-cards .swiper-slide h2 {
    margin-top: 2.8rem;
}

.action-cards .swiper-slide img {
    max-height: 50%;
    max-width: 80%;
    margin: 60px 0 40px;
    pointer-events: none;
}

.action-cards b {
    font-weight: 500;
}

.action-cards p {
    font-size: 14px;
    line-height: 1.5;
    color: var(--ion-color-step-600, #60646b);
}

.action-cards p b {
    color: var(--ion-text-color, #000000);
}
